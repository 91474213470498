import React from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  ContainerCustom,
  Content,
  H2,
  Medailon,
  PaddingWrapper,
  Slider,
} from "@igloonet-web/shared-ui"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import styled from "styled-components"
import { Container } from "reactstrap"
import PenguinsTimeline from "../../../components/reference-detail/penguins-timeline"
import { TIMELINE_SPOLECNOSTE } from "../../../model/reference-timeline-spolecnost-e"
import Uvod from "../../../images/reference/spolecnost-e/uvodni.jpeg"
import Aranzerie1 from "../../../images/reference/spolecnost-e/aranzerie1.jpg"
import Aranzerie2 from "../../../images/reference/spolecnost-e/aranzerie2.jpg"
import Aranzerie3 from "../../../images/reference/spolecnost-e/aranzerie3.jpg"
import FK2 from "../../../images/reference/spolecnost-e/FK2.png"
import Kampan1 from "../../../images/reference/spolecnost-e/kampan1.png"
import KampanValentyn from "../../../images/reference/spolecnost-e/kampanvalentyn.png"
import MindMana from "../../../images/reference/spolecnost-e/mindmapa.png"
import OgImage from "../../../images/reference/spolecnost-e/og-image.png"
import PO from "../../../images/reference/spolecnost-e/PO.png"
import UT from "../../../images/reference/spolecnost-e/UT.png"
import ST from "../../../images/reference/spolecnost-e/ST.png"
import CT from "../../../images/reference/spolecnost-e/CT.png"
import PA from "../../../images/reference/spolecnost-e/PA.png"
import SO from "../../../images/reference/spolecnost-e/SO.png"
import NE from "../../../images/reference/spolecnost-e/NE.png"
import Logo from "../../../images/reference/spolecnost-e/logo.jpg"
import QR from "../../../images/reference/spolecnost-e/QR.png"
import SpolecnostE2 from "../../../images/reference/spolecnost-e/spolecnostE2.png"
import Alenka from "../../../images/reference/spolecnost-e/alenka.jpg"
import Terka from "../../../images/reference/spolecnost-e/terka.jpg"
import Lukas from "../../../images/team/mini/lukas.jpg"
import Eliska from "../../../images/team/mini/eliska.jpg"

const data = [
  {
    id: 1,
    imageSrc: PO,
    name: "pondělí",
  },
  {
    id: 2,
    imageSrc: UT,
    name: "úterý",
  },
  {
    id: 3,
    imageSrc: ST,
    name: "středa",
  },
  {
    id: 4,
    imageSrc: CT,
    name: "čtvrtek",
  },
  {
    id: 5,
    imageSrc: PA,
    name: "pátek",
  },
  {
    id: 6,
    imageSrc: SO,
    name: "sobota",
  },
  {
    id: 7,
    imageSrc: NE,
    name: "neděle",
  },
]

const ThreeImages = styled.div`
  position: relative;
  margin: auto;

  img {
    max-width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    img {
      margin-bottom: 0;
    }

    .middleImgWrapper {
      margin: 8rem 0 0 3rem;
    }

    .bottomImgWrapper {
      margin: -10rem auto 0;
      left: 0;
      max-width: 40%;
      z-index: 5;
    }
  }
`

const SpolecnostE: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Dárcovská kampaň pro neziskovku | igloonet.cz</title>
        <meta
          name="description"
          content="„Od upevnění brandu přes tvorbu dárcovského programu až po první kampaně."
        />
        <meta
          property="og:title"
          content="Dárcovská kampaň pro neziskovku | igloonet.cz"
        />
        <meta property="og:image" content={OgImage} />
      </Helmet>

      <ReferenceHeader
        companyName="SPOLEČNOST E"
        heading="Společně se Společností E: od upevnění brandu přes tvorbu dárcovského programu až po první kampaně"
        date="leden 2022 -&nbsp;současnost"
      />

      <ReferenceImageText
        right
        img={Uvod}
        eager
        alt=""
        logo={Logo}
        logoAlt="logo Společnost E"
        link="//spolecnost-e.cz/"
        width="640"
        height="549"
        halfImgSize
      >
        <p>
          Žít lépe život s&nbsp;epilepsií. To je hlavní hnací motor neziskovky
          Společnost&nbsp;E. Odhodlání jejích zaměstnanců a&nbsp;členů je
          obrovské a&nbsp;poslání jasné. Pro plnění svého cíle ale potřebují
          dary i&nbsp;dobrovolníky. Provedeme vás bod po bodu tím, jak jsme se
          zhostili nelehkého úkolu upevnit brand a&nbsp;nalézt neziskové
          organizaci nové dárce.
        </p>
      </ReferenceImageText>

      <PaddingWrapper>
        <ReferenceImageText responsiveTextOrder img={SpolecnostE2}>
          <p>
            <a href="//spolecnost-e.cz/" target="_blank" rel="noreferrer">
              Společnost&nbsp;E
            </a>{" "}
            je nezisková organizace podporující lidi s&nbsp;epilepsií. Motivaci
            mají jasnou&nbsp;– pomoci lidem s&nbsp;epilepsií a&nbsp;jejich
            blízkým žít lepší život, destigmatizovat a&nbsp;mírnit diskriminaci.
            Jejich působení zahrnuje mnoho aktivit. Pořádají workshopy pro lidi
            s&nbsp;epilepsií, školy i&nbsp;firmy, poskytují odborné
            i&nbsp;právní poradenství a&nbsp;v&nbsp;neposlední řadě rozvíjí
            projekt Aranžérie.
          </p>
        </ReferenceImageText>
        <ReferenceImageText>
          <p>
            <a href="//aranzerie.cz/" target="_blank" rel="noreferrer">
              Aranžérie
            </a>{" "}
            je sociální podnik zaměstnávající lidi s&nbsp;epilepsií. Jde
            o&nbsp;květinovou dílnu s&nbsp;e&#8209;shopem. Veškerý výtěžek za
            květiny putuje právě Společnosti&nbsp;E, která tyto prostředky
            využívá pro další pomoc. Přes Aranžérii si můžete objednat květiny
            na svatbu, darovat je do domovů pro seniory{" "}
            <a
              href="//aranzerie.cz/kytka-pro-babi-a-dedu/"
              target="_blank"
              rel="noreferrer"
            >
              (projekt Kytka pro babi a&nbsp;dědu)
            </a>{" "}
            nebo si třeba zajít na workshop vázání či aranžování.
          </p>
        </ReferenceImageText>

        <ContainerCustom className="d-flex m-auto">
          <ThreeImages>
            <div className="d-md-flex">
              <img src={Aranzerie2} alt="" width="500" height="500" />
              <div className="middleImgWrapper">
                <img src={Aranzerie3} alt="" width="450" height="301" />
              </div>
            </div>
            <div className="bottomImgWrapper">
              <img src={Aranzerie1} alt="" width="600" height="400" />
            </div>
          </ThreeImages>
        </ContainerCustom>
      </PaddingWrapper>

      <PaddingWrapper biggerPaddingTop>
        <Container className="text-center">
          <H2>Cíle spolupráce</H2>
          <p>
            Jako primární cíl jsme si stanovili nábor nových dárců; růst počtu
            dárců na webu i&nbsp;skrz portál Darujme.cz a&nbsp;více nákupů
            v&nbsp;Aranžérii. Šíření značky a&nbsp;zvyšování povědomí jsme si
            nastavili jako sekundární prioritu. Společnost&nbsp;E zahrnuje celou
            řadu aktivit, skrze které jsme těchto cílů mohli dosáhnout.
            A&nbsp;my se do toho s&nbsp;chutí pustili.
          </p>
        </Container>
      </PaddingWrapper>

      <Medailon
        personName="Alena Červenková"
        position="předsedkyně, koordinátorka projektů<br>a fundraiserka"
        img={Alenka}
        noCover
      >
        <p>
          Vždy je pro mne fajn mít v&nbsp;zádech parťáky z&nbsp;řad odborníků,
          kteří nás srozumitelně a&nbsp;lidsky provází oblastí, ve které nejsme
          „jako ryba ve vodě“. Eliška s&nbsp;Lukášem z&nbsp;igloonetu nás
          trpělivě a&nbsp;krok za krokem provází od definování marketingové
          strategie až po realizaci konkrétních kampaní. Hlavně nás ale učí
          a&nbsp;vedou k&nbsp;samostatnosti a&nbsp;nezávislosti na jejich
          budoucí podpoře, což pomalu, ale jistě přináší své ovoce. Nejenže
          v&nbsp;týmu díky nim získáváme nové dovednosti a&nbsp;kompetence, ale
          také nové dárce a&nbsp;podporovatele projektů Společnosti
          E.&nbsp;Děkujeme za skvělou spolupráci, díky které můžeme dál
          a&nbsp;lépe zlepšovat život lidem s&nbsp;epilepsií.
        </p>
      </Medailon>

      <PaddingWrapper noPaddingBottom>
        <H2>Jak jsme postupovali?</H2>
        <PenguinsTimeline data={TIMELINE_SPOLECNOSTE} />

        <PaddingWrapper noPaddingBottom>
          <Content>
            <ol>
              <li>
                <strong>Workshop</strong>
                <p>
                  spolupráci jsme odstartovali strukturovaným workshopem, který
                  jsme rozdělili do dvou dnů, abychom byli čilí a&nbsp;schůzka
                  nebyla příliš únavná. Povídali jsme si
                  o&nbsp;Společnosti&nbsp;E a&nbsp;Aranžérii jako o&nbsp;značce,
                  o&nbsp;její vizi. Probrali jsme i&nbsp;brandové symboly, tón
                  komunikace nebo cílové skupiny. Výsledkem byla jedna obrovská
                  mind mapa shrnující vše, co jsme ke spolupráci potřebovali
                  znát.
                </p>
              </li>

              <Container className="mx-auto mb-5 text-center">
                <img
                  src={MindMana}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                  width={1500}
                  height={910}
                />
                <p className="text-center pt-3">
                  <em>Ukázka části mind mapy</em>
                </p>
              </Container>

              <PaddingWrapper>
                <Medailon
                  noMargin
                  fullWidth
                  noCover
                  noPenguin
                  personName="Lukáš Havlík"
                  position="vedoucí marketingu"
                  img={Lukas}
                >
                  <p>
                    Workshop je u&nbsp;tohoto typu spolupráce snad
                    nejdůležitějším bodem. Krom zmíněného přináší také
                    mimořádnou příležitost se vzájemně poznat, což další práci
                    velmi pomáhá, a&nbsp;věnovat čas tématům, na které firmám
                    a&nbsp;organizacím nemusí zbývat v každodenní práci prostor.
                    Na workshop si zpravidla zveme zástupce vedení, středního
                    managementu i&nbsp;specialistů – pokrytí názorů/reality tak
                    bývá poměrně komplexní.
                  </p>
                </Medailon>
              </PaddingWrapper>

              <li>
                <strong>
                  Rozvoj webu, analytiky a&nbsp;kontrola reklamních účtů
                </strong>
                <p>
                  weby Aranžérie i&nbsp;Společnosti&nbsp;E jsme podrobili naší
                  přísné kontrole marketingovým očkem. Navrhli jsme změny, které
                  jsme považovali pro účely propagace za nutné. Spolu s&nbsp;tím
                  jsme pomohli s&nbsp;nastavením analytiky a&nbsp;dalšího
                  měření, abychom mohli dopady on&#8209;line aktivit sledovat
                  a&nbsp;vyhodnocovat. Pomohli jsme nastavit cookie lištu
                  i&nbsp;Facebookový pixel. Zkontrolovali jsme také nastavení
                  e&#8209;mailingového nástroje a&nbsp;založili Facebook
                  Business manager.
                </p>
              </li>

              <li>
                <strong>První kampaně pro Aranžérii</strong>
                <p>
                  společně jsme vymysleli a&nbsp;vytvořili první kampaně na
                  Facebooku a&nbsp;Instagramu pro Aranžérii. Propagovali jsme
                  květinovou nabídku k&nbsp;příležitosti Valentýna, následně
                  k&nbsp;MDŽ i&nbsp;svatebního servisu.
                </p>
              </li>

              <Container className="mx-auto mb-5 text-center">
                <img
                  src={KampanValentyn}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                  width={325}
                  height={630}
                />
              </Container>

              <li>
                <strong>Znovurozjetí a&nbsp;propagace dárcovského klubu</strong>
                <p>
                  jednou z&nbsp;hlavních výzev Společnosti&nbsp;E pro rok 2022
                  bylo znovuobnovení dárcovského klubu, který kdysi nesl název
                  Fialový klub (fialová je totiž barva epilepsie). I&nbsp;tohoto
                  úkolu jsme se zhostili společně. Společně jsme navrhli
                  fungování klubu i&nbsp;obsah na web, pomohli jsme
                  s&nbsp;úpravou a grafickou podobou stránky, jednotlivými
                  prvky, rozložením stránky i&nbsp;textací. Vznikl tak dárcovský
                  klub, jehož členem se stanete ve chvíli, kdy
                  Společnosti&nbsp;E přispějete na fungování – ať už
                  jednorázovým, či pravidelným darem. Fialový klub si po svém
                  znovunarození zasloužil výraznější propagaci.
                </p>
              </li>

              <Container className="mx-auto mb-5 text-center">
                <img
                  src={Kampan1}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                  width={325}
                  height={681}
                />
                <p className="text-center pt-3">
                  <em>Ukázka kampaně pro Fialový klub</em>
                </p>
              </Container>

              <Container className="mx-auto mb-5 text-center">
                <img
                  src={FK2}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                  width={650}
                  height={420}
                />
                <p className="text-center pt-3">
                  <em>Ukázka webu Fialového klubu</em>
                </p>
              </Container>

              <li>
                <strong>Rozvoj e&#8209;mailingu</strong>
                <p>
                  e&#8209;mailing jsme vnímali jako potenciálně silný kanál.
                  S&nbsp;ohledem na dvě odlišné cílovky Společnosti&nbsp;E
                  (dárci a&nbsp;lidé s epilepsií) jsme navrhli šablonu, která
                  bude variabilní a&nbsp;použitelná pro obě zmiňované skupiny.
                  V&nbsp;rámci rozvoje e&#8209;mailingu jsme zkontrolovali jeho
                  nastavení, pročistili databázi a&nbsp;poskytli klientovi
                  úvodní support při práci s&nbsp;novou šablonou.
                </p>
              </li>

              <li>
                <strong>Podcasty</strong>
                <p>
                  o&nbsp;epilepsii se ve společnosti moc často nemluví. Lidé si
                  ji zpravidla představují jen jako záchvaty. Ona ale ovlivňuje
                  rodiny, přátelské vztahy i&nbsp;pracovní zkušenosti.
                  A&nbsp;právě těmto otázkám se věnoval podcast, který
                  Společnost&nbsp;E spolu s&nbsp;
                  <a href="//promopeople.cz/" target="_blank" rel="noreferrer">
                    PromoPeople
                  </a>{" "}
                  vytvořila. My jsme se podíleli na definici témat a&nbsp;otázek
                  pro rozhovory, feedbackovali jsme výsledek a&nbsp;poté jej
                  i&nbsp;propagovali. Podcast si můžete pustit{" "}
                  <a
                    href="//youtube.com/@spolecnoste4693/videos/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    tady
                  </a>
                  .
                </p>
              </li>

              <li>
                <strong>Školení reklamy na Facebooku</strong>
                <p>
                  s&nbsp;klienty se vždy snažíme předávat si vzájemné znalosti
                  a&nbsp;především se učit nové věci. Nechceme si „škudlit“
                  vědomosti pro sebe, a&nbsp;pokud to jde, rádi je předáváme
                  internímu týmu. Proto jsme v&nbsp;další fázi spolupráce
                  přichystali rozsáhlé školení reklamy na Facebooku
                  a&nbsp;proškolili klienta, jak s&nbsp;reklamou pracovat.
                  Ukázali jsme si, jak vytvářet reklamy, nastavovat publika,
                  pracovat s&nbsp;cílením i&nbsp;kreativou. V&nbsp;následujících
                  měsících si vedoucí marketingu a&nbsp;PR Tereza nastavovala
                  reklamy v&nbsp;Business manageru sama. Vždy jsme jí ale
                  k&nbsp;ruce a&nbsp;nastavení propagace i&nbsp;obsah kampaní
                  společně konzultujeme, případně opravíme zádrhele
                  a&nbsp;poradíme, když se něco nedaří.
                </p>
              </li>

              <li>
                <strong>Dárcovské kampaně pro Společnost E</strong>
                <p>
                  před nadcházející sezónou (září&nbsp;– prosinec) jsme společně
                  vymysleli a&nbsp;zrealizovali ještě několik dalších kampaní.
                  Zábavnou a&nbsp;kreativní formou se snažili o&nbsp;osvětu
                  a&nbsp;získání nových dárců. Jako příklad můžeme uvést kampaň
                  Giving Tuesday.
                </p>
              </li>

              <PaddingWrapper>
                <p>
                  Společnost&nbsp;E vzdělává široké okolí, aby lidem
                  s&nbsp;epilepsií lépe rozumělo. Vytvořili jsme proto kampaň
                  popisující týden člověka s&nbsp;epilepsií. Prohlédněte si ji.
                  ↓
                </p>
                <Slider noPadding data={data} />
              </PaddingWrapper>

              <li>
                <strong>Mentoring a konzultace</strong>
                <p>
                  po rozjetí několika prvních kampaní jsme s&nbsp;klientem
                  přešli na spolupráci formou mentoringu a&nbsp;podpory.
                  Pravidelně konzultujeme marketingové aktivity, kontrolujeme
                  kampaně a&nbsp;radíme vždy, když si o&nbsp;to klient řekne.
                </p>
              </li>
            </ol>
          </Content>
        </PaddingWrapper>
      </PaddingWrapper>

      <PaddingWrapper>
        <Medailon
          noMargin
          noCover
          personName="Tereza Leperlier "
          position="Vedoucí PR a marketingu"
          img={Terka}
        >
          <p>
            V&nbsp;neziskovém sektoru je běžné, že jeden člověk zastupuje více
            pozic z&nbsp;důvodu omezených financí. Je však klíčové držet krok
            s&nbsp;dobou a dokázat se dobře orientovat v&nbsp;oblastech, které
            jsou pro chod organizace a&nbsp;sociálního podniku nezbytné. Mezi ně
            neodmyslitelně patří marketing a&nbsp;správa sociálních sítí. Mít
            v&nbsp;záloze profesionály, kteří vás dokáží pochopit
            a&nbsp;pomáhají vám v&nbsp;šíření vaší myšlenky, ať už se jedná
            o&nbsp;neziskovou organizaci či sociální podnik, je prostě
            nepostradatelné. S&nbsp;Eliškou a&nbsp;Lukášem v&nbsp;zádech jsme
            dokázali oslovit širokou veřejnost a&nbsp;dát práci, které se
            věnujeme, hlubší smysl.
          </p>
        </Medailon>
      </PaddingWrapper>

      <PaddingWrapper>
        <ContainerCustom>
          <p className="d-flex justify-content-center pb-3">
            <strong>
              Pomozte i&nbsp;vy žít lepší život s&nbsp;epilepsií. QR kód vás
              zavede na stránku s&nbsp;možností{" "}
              <a
                href="//spolecnost-e.cz/podporte/"
                target="_blank"
                rel="noreferrer"
              >
                darovat
              </a>
            </strong>
          </p>
          <div className="d-flex m-auto">
            <div className="bottomImgWrapper mx-auto">
              <a
                href="//spolecnost-e.cz/jednorazovy-dar/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={QR} alt="" width="300" height="300" />
              </a>
            </div>
          </div>
        </ContainerCustom>
      </PaddingWrapper>

      <PaddingWrapper>
        <Container className="text-center">
          <H2>Výsledky</H2>
          <p>
            Za první rok spolupráce se Společnosti&nbsp;E&nbsp;podařilo
            i&nbsp;díky on&#8209;line reklamě vybrat přes 100&nbsp;tisíc Kč.
            Některé z&nbsp;darů jsou pravidelné, chodí tedy na měsíční bázi.
          </p>
        </Container>
      </PaddingWrapper>

      <PaddingWrapper>
        <Medailon
          noMargin
          noCover
          personName="Eliška"
          position="vedoucí projektu"
          img={Eliska}
        >
          <p>
            Na spolupráci s&nbsp;neziskovkou mě nejvíc baví, že v&nbsp;práci
            vidím hluboký smysl. Máme sice na marketingové aktivity limitovaný
            rozpočet, líbí se mi ale hledat cesty, jak dostat poselství
            Společnosti&nbsp;E mezi co nejvíce lidí s&nbsp;vědomím, že je to pro
            dobrou věc. Právě mentoring a&nbsp;menší workshopy pro klienta jsou
            skvělá forma, jak to dělat i&nbsp;s&nbsp;omezenými financemi.
          </p>
        </Medailon>
      </PaddingWrapper>

      <PaddingWrapper biggerPaddingTop noPaddingBottom>
        <ContactForm contact="adam" />
      </PaddingWrapper>
    </Layout>
  )
}

export default SpolecnostE
