import Penguin1 from "../images/reference/1.png"
import Penguin2 from "../images/reference/2.png"
import Penguin3 from "../images/reference/3.png"
import Penguin4 from "../images/reference/vyvoj-4-bez-textu.png"
import Penguin5 from "../images/reference/5.png"
import VerticalPenguin1 from "../images/reference/vertical-1.png"
import VerticalPenguin2 from "../images/reference/vertical-2.png"
import VerticalPenguin3 from "../images/reference/vertical-3.png"
import VerticalPenguin4 from "../images/reference/vertical-4.png"
import VerticalPenguin5 from "../images/reference/vertical-5.png"

export const TIMELINE_SPOLECNOSTE = [
  {
    img: Penguin1,
    verticalImg: VerticalPenguin1,
    text: "Prvotní workshop",
  },
  {
    img: Penguin2,
    verticalImg: VerticalPenguin2,
    text: "Prioritizace marketingových aktivit",
  },
  {
    img: Penguin3,
    verticalImg: VerticalPenguin3,
    text: "Nasazení prvních kampaní",
  },
  {
    img: Penguin4,
    verticalImg: VerticalPenguin4,
    text: "Znovurozjetí dárcovského klubu",
  },
  {
    img: Penguin5,
    verticalImg: VerticalPenguin5,
    text: "Mentoring a podpora",
  },
]
